<template>
  <div class="download-page">
    <div class="download-blank">
      <div class="title">泰祺考研</div>
      <div class="intro">专注经管类考研22年</div>
      <div class="download-info">
        <div class="top">
          <div class="item" :class="{'item-selected' : index === 0}" @click="changeDownload(0)">
            <img :src="index === 0 ? require('../assets/img/apple_selected_icon.png') : require('../assets/img/apple_icon.png')">
            <span>ios下载</span>
          </div>
          <div class="item" :class="{'item-selected' : index === 1}" @click="changeDownload(1)">
            <img :src="index === 1 ? require('../assets/img/android_selected_icon.png') : require('../assets/img/android_icon.png') ">
            <span>Android下载</span>
          </div>
        </div>
        <div class="bottom">
          <div class="code-info">
            <img v-if="index === 0" :src="configInfo.ios_image">
            <img v-if="index === 1" :src="configInfo.android_image">
          </div>
        </div>
      </div>
    </div>
    <div class="download-right">
      <img src="../assets/img/download_img.png" />
    </div>
  </div>
</template>

<script>
import * as Utils from '@/utils/index';
export default {
  name: 'appDownload',
  data() {
    return {
      index: 0,
      configInfo: {} // 配置信息
    };
  },
  mounted() {
    this.configInfo = Utils.getLocalStorage('config_info');
    this.$nextTick(() => {
      this.$parent.$refs.mainNav.style.display = 'none';
    });
  },
  methods: {
    changeDownload(val) {
      this.index = val;
    }
  },
  destroyed() {
    this.$parent.$refs.mainNav.style.display = 'block';
  }
};
</script>

<style lang="scss" scoped>
.download-page {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 315px);
  background: url("../assets/img/download_bg.png") bottom no-repeat, linear-gradient(56deg,#2066ff,#3191ff);
  background-size: cover;
  overflow: hidden;
  .download-blank {
    display: flex;
    flex-direction: column;
    margin: 166px 205px 0 0;
    line-height: 1;
    .title {
      font-size: 60px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 29px;
    }
    .intro {
      font-size: 36px;
      font-weight: 400;
      color: #FEFEFE;
      margin-bottom: 52px;
    }
    .download-info {
      .top {
        display: flex;
        margin-bottom: 40px;
        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 160px;
          height: 61px;
          border: 1px solid #FFFFFF;
          border-radius: 10px;
          cursor: pointer;
          img {
            width: 23px;
            height: 27px;
            margin-right: 10px;
          }
          span {
            font-size: 18px;
            color: #FFFFFF;
          }
        }
        .item+.item {
          width: 180px;
          margin-left: 20px;
          img {
            width: 25px;
            height: 29px;
          }
        }
        .item-selected {
          background: #FFFFFF;
          span {
            color: #2869F5;
          }
        }
      }
      .bottom {
        width: 180px;
        height: 180px;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 20px;
        padding: 10px;
        .code-info {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 160px;
          height: 160px;
          background: #FFFFFF;
          border-radius: 16px;
          img {
            width: 140px;
            height: 140px;
          }
        }
      }
    }
  }
  .download-right {
    margin: 49px 0 14px;
    img {
      width: 695px;
      height: 702px;
    }
  }
}
</style>
